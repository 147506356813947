.ssn-bg {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
  background: rgb(177, 63, 131);
  background: linear-gradient(
    180deg,
    rgba(177, 63, 131, 1) 52%,
    rgba(178, 157, 169, 1) 97%
  );
  position: relative;
}
.megaMain {
  position: absolute;
  top: 5px;
  width: 60%;
}
.alomostThere {
  position: absolute;
  width: 100%;
  top: 80px;
}
.main-heading-verify {
  width: 100%;
  padding: 5px 0;
  background-color: #f2f2f2;
}
.form-card {
  position: relative;
  z-index: 22;
}
.verify-filed,
.verify-filed2 {
  border: none;
  color: transparent;
}
.verify-filed::-webkit-file-upload-button {
  visibility: hidden;
}
.verify-filed::before {
  content: "TAKE PHOTO OF ID";
  color: #ffffff;
  display: inline-block;
  background: #1682da;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  padding: 8px 25px;
  width: 100%;
  text-align: center;
}
.verify-filed2::-webkit-file-upload-button {
  visibility: hidden;
}
.verify-filed2::before {
  content: "TAKE SELFIE WITH ID";
  color: #ffffff;
  display: inline-block;
  background: #1682da;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  padding: 8px 25px;
  width: 100%;
  text-align: center;
}

.new-btn {
  background-color: #1682da;
  font-size: 14px;
  font-weight: 500;
}
.btn-next {
  display: block;
  margin-left: auto;
  padding: 4px 20px;
  border-radius: 5px;
  border: 0;
  background: #feb161;
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: normal;
}
