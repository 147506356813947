@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.tmian{
    padding: 20px 20%;
}
.tmain-head {
  font-size: 2em;
  font-weight: bold;
}
.tsub-head {
  color: #000000;
}
.tstrt {
  background-color: #739fef;
  color: #ffffff;
}
.tinput {
    text-align: center;
    width: 100%;
    padding: 8px 8px;
    font-size: 1rem;
    letter-spacing: 0.062rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 4px;
    background-color: white;
}
.tbtn{
    background-color: #FFA07A;
    color: white;
    padding: 14px 20px;
    margin: 2px 0;
    border: none;
    cursor: pointer;
    width: 40%;
    opacity: 0.9;
    border-radius: 4px;
}
.twarn{
    color: red;
}
.timg{
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.load-main{
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #000000;
}
.load-main p{
  color: #ffffff;
}

/* HTML: <div class="loader"></div> */
.dots {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #f9f8f8, -20px 0 #6e6d6d22;background: #ffffff }
    33% {box-shadow: 20px 0 #ffffff, -20px 0 #6e6d6d22;background: #6e6d6d22}
    66% {box-shadow: 20px 0 #6e6d6d22,-20px 0 #ffffff; background: #6e6d6d22}
    100%{box-shadow: 20px 0 #6e6d6d22,-20px 0 #ffffff; background: #ffffff }
}