.ageCheckPopupInner {
    width: 600px;
    max-width: 100%;
    height: 97vh;
    margin: auto;
    padding: 1em 2.8em 40px;
    z-index: 2000;
    position: relative;
    /* background: url('../img/agepopup_bg.png') no-repeat; */
    background-size: 100% 100%;
    color: #4f0202;
    text-align: center;
}
.ageimng {
    width: 40%;
    margin-top: 3%;
}

.terms-container {
    border: 4px inset lightgray;
    padding: 0 12px;
    max-height: calc(100% - 344px);
    overflow: auto;
    color: #555555;
}
.terms-container .h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.terms-container .h2 {
    text-align: center;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.terms-container .h3 {
    font-size: 10px;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 2px;
}
.terms-container .simpleText {
    text-align: justify;
    margin-bottom: 4px;
    font-size: 5pt;
}
.terms-container .checkbox {
    display: inline-flex;
    align-items: center;
}
.terms-container .checkbox input {
    width: 24px;
    height: 24px;
    accent-color: forestgreen;
}

.terms-container .checkbox span {
    margin-left: 14px;
    text-align: left;
    font-size: 14px;
}
.agree-container {
    margin-top: 6px;
}

.agree-container .text {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #3a7ec8;
    text-transform: uppercase;
}